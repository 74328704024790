// Tamil
export default{
    form: {
        startSurveyButton: "ஆரம்பிக்கலாமா",
        nextButton: "அடுத்தது",
        submitAnswerButton: "பதிலளிக்கவும்",
        continueButton: "தொடரவும்",
        finishButton: "முடிக்கவும்",
        headerPercentageCompletion: 'முடிந்தது',
        headerQuestionsCompleted: 'கேள்விகள்',
        headerDefaultErrorMessage: 'ஓப்ப்ஸ். ஏதோ தவறு நடந்தது.',
        brandingPoweredBy: 'இனால் இயக்கப்பட்டது',
        brandingPromoWhySurvey: 'நீங்கள் <b>{product}</b> பயன்படுத்த முடியும் போது, ஏன் கருத்துக்கணிப்பு?',
        brandingPromoGetStarted: 'இன்றே தொடங்குங்கள்!',
        submissionPending: "உங்கள் வொய்ஸ்ஃபாரம் சமர்ப்பிக்கப்படுகிறது. உங்கள் உலாவி சாளரத்தை மூடாதீர்கள்.",
        submissionCanCloseWindow: "உங்கள் வொய்ஸ்ஃபாரம் வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது. நீங்கள் சாளரத்தை இப்போது மூடலாம் 👍",
        mediaAttachmentOverlayCloseButton: "மூடுக",
        expiredFormHeader: "தோன்றி சென்றதற்கு நன்றி!",
        expiredFormMessage: "இந்த கருத்துக்கணிப்பு காலாவதியானது என்று தெரிகிறது. வொய்ஸ்ஃபாரம் உருவாக்கியவரை தொடர்பு கொள்ளவும்.",
        notPublishedFormHeader: "வொய்ஸ்ஃபாரம் வெளியிடப்படவில்லை!",
        notPublishedFormMessage: "இந்த கருத்துக்கணிப்பு வெளியிடப்படவில்லை என்று தெரிகிறது. வொய்ஸ்ஃபாரம் உருவாக்கியவரை தொடர்பு கொள்ளவும்.",
        notPublishedFormRetryButton: "மீண்டும் முயற்சிக்கவும்",
        errorFormHeader: "ஓப்ப்ஸ்!",
        errorFormMessage: "ஏதோ தவறாக இருக்கலாம். பின்னர் முயற்சிக்கவும்.",
        errorFormRetryButton: "மீண்டும் முயற்சிக்கவும்",
        emptyFormHeader: "தோன்றி சென்றதற்கு நன்றி!",
        emptyFormMessage: "ஓப்ப்ஸ்! இந்த படிவத்தில் கேள்விகள் அல்லது பூர்த்தி செய்ய வேண்டிய புலங்கள் இல்லை என்று தெரிகிறது. இந்த சிரமத்திற்கு மன்னிக்கவும். தயவுசெய்து படிவத்தின் உருவாக்கியவரை தொடர்பு கொண்டு, இந்த பிரச்சினையை தெரிவித்துக் கொள்ளவும். உங்கள் புரிதலுக்கும் ஒத்துழைப்பிற்கும் நன்றி!",

        restoreFormHeader: "நீங்கள் ஒரு படிவத்தை தொடர நின்று விட்டீர்கள் போல!",
        restoreFormMessage: "நீங்கள் இதுவரை முடிந்த இடத்திலிருந்து தொடங்க விரும்புகிறீர்களா?",
        restoreFormButton: "படிவத்தை தொடரவும்",
        restoreFormButtonNew: "மீண்டும் தொடங்கவும்",

        audioPermissionHeader: "உங்கள் குரலால் பதிலளிக்க விரும்புகிறீர்களா?",
        audioPermissionMessage: "பேசுவது பதிலளிக்க துரிதமாகவும் எளிதாகவும் இருக்கும்.",
        audioPermissionAcceptButton: "ஆம், நான் விரும்புகிறேன்",
        audioPermissionDenyButton: "இல்லை, நன்றி",
        audioPermissionErrorHeader: "மைக்ரோஃபோன் கிடைக்கவில்லை",
        audioPermissionErrorMessage: "மைக்ரோஃபோன் அணுகலை இயக்க உங்கள் உலாவி அனுமதிகளை சரிபார்க்கவும்.",
        audioPermissionErrorButton: "சரி",
        audioRecorderFailedUpload: "பதிவு பதிவேற்றம் தோல்வியுற்றது",
        silenceDetectorError: "உங்கள் குரல் கேட்கவில்லை. உங்கள் மைக்ரோஃபோன் விருப்பங்களை சரிபார்க்கவும். குறிப்பு: இது உங்கள் வெளிப்புற மைக்ரோஃபோன் இருக்கலாம்.",
        audioRecorderButtonRecord: "பதிவு செய்யவும்",
        audioRecorderButtonRecordMore: "மேலும் பதிவு செய்யவும்",
        voiceResponseSelectionMessage: "பதில் அளிக்க விரும்பும் முறையை தேர்ந்தெடுக்கவும்...",
        voiceResponseSelectionOrDivider: "அல்லது",
        voiceResponseTextInputPlaceholder: "பதில் எழுத்தளிக்கவும்",
        voiceResponseTextInputCharacterCounterMinMessage: "குறைந்தது {min} எழுத்துக்களை உள்ளிடவும்",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} எழுத்துகள் (குறைந்தது {min} எழுத்துகள்)",
        voiceResponseTextInputCharacterValidationMessage: "{count} எழுத்துகள் (குறைந்தது {min} எழுத்துகள்)",

        // checkbox
        checkboxValidationTooFew: "நீங்கள் குறைந்தது {min} விருப்பத்தை தேர்ந்தெடுக்க வேண்டும்",
        checkboxValidationTooMany: "நீங்கள் அதிகபட்சம் {max} விருப்பங்களை மட்டுமே தேர்ந்தெடுக்க வேண்டும்",
        checkboxNoneOfTheAboveOption: "மேலே எதுவும் இல்லை",
        checkboxInvalidAnswer: "செல்லுபடியாகாத பதில்.",

        // datepicker
        datePickerPlaceholder: "தேதியைத் தேர்ந்தெடுக்கவும்",

        // dropdown
        // email
        emailLabel: "மின்னஞ்சல்",
        emailInvalid: "செல்லுபடியாகாத மின்னஞ்சல்.",
        // file-upload
        fileUploadPluginNameCamera: "கேமரா",
        fileUploadPluginNameCameraVideo: "வீடியோ பதிவு செய்யவும்",
        fileUploadPluginScreenCast: "திரை ஒளிபரப்பு",
        fileUploadVideoImportFilesDefault: 'வீடியோ பதிவு செய்ய அல்லது பதிவேற்றவும்:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'வீடியோ பதிவுக்கான விருப்பத்தைத் தேர்ந்தெடுக்கவும்:',
        fileUploadVideoImportFilesNoCamera: 'திரை பதிவு செய்ய ஒரு பொத்தானை அழுத்தவும்',
        fileUploadVideoImportFilesNoScreenCast: 'வீடியோ பதிவு செய்ய ஒரு பொத்தானை அழுத்தவும்',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'வீடியோ பதிவு செய்யும் விருப்பங்கள் எதுவும் கிடைக்கவில்லை',

        // matrix
        matrixValidationMessage: "பதில் செல்லுபடியாக உள்ளது.",
        matrixRow: "வரிசை",
        matrixColumn: "நெடுவரிசை",
        matrixRowRequired: "வரிசை {rowTitle} தேவை.",
        matrixRadioGroup: "வானொலி குழு",
        matrixCheckboxGroup: "செய்து படிவக் குழு",
        matrixGroupRequired: "வரிசை {rowTitle} க்கான. {type} {groupTitle} தேவை.",
        matrixColumnRequired: "வரிசை {rowTitle} க்கான. நெடுவரிசை {columnTitle} தேவை.",
        matrixColumnInvalid: "வரிசை {rowTitle} க்கான. நெடுவரிசை {columnTitle} செல்லுபடியாகாது.",
        matrixRequired: "தேவை.",
        matrixNumericMustBeNumber: "எண் இருக்க வேண்டும்.",
        matrixNumericMustBeGreaterThenMin: "{min} விட பெரியதாக இருக்க வேண்டும்.",
        matrixNumericMustBeLessThenMax: "{max} விட குறைவாக இருக்க வேண்டும்.",
        matrixNumericMustBeInteger: "முழு எண் இருக்க வேண்டும்.",
        matrixNumericInvalidNumber: "செல்லுபடியாகாத எண்.",

        // name
        nameLabel: "பெயர்",
        nameInvalid: "முதல் மற்றும் கடைசி பெயரை குறிப்பிடுக.",

        // nps
        npsNotLikely: "இல்லாமல் இருப்பது சாத்தியம் இல்லை",
        npsExtremelyLikely: "மிகவும் சாத்தியம்",

        // numeric input
        numericInputRequired: "தேவை.",
        numericInputMustBeNumber: "எண் இருக்க வேண்டும்.",
        numericInputMustBeGreaterThenMin: "{min} விட பெரியதாக இருக்க வேண்டும்.",
        numericInputMustBeLessThenMax: "{max} விட குறைவாக இருக்க வேண்டும்.",
        numericInputMustBeInteger: "முழு எண் இருக்க வேண்டும்.",
        numericInputInvalidNumber: "செல்லுபடியாகாத எண்.",
        numericInputPlaceholder: "எண்ணைத் தட்டச்சு செய்யவும்",

        // phone
        phoneInvalid: "தொலைபேசி எண் செல்லுபடியாகாது.",
        phoneCountrySelectorLabel: 'நாட்டு குறியீடு',
        phoneCountrySelectorError: 'நாடு தேர்வு செய்யவும்',
        phoneNumberLabel: 'தொலைபேசி எண்',
        phoneExample: 'உதாரணம்:',

        // boolean
        booleanYesLabel: "ஆம்",
        booleanNoLabel: "இல்லை",

        //questionStep
        questionStepAudioQuestionLabel: "ஒலி கேள்வி",

        // errors
        invalidPhoneNumber: "{phone} செல்லுபடியாகாத தொலைபேசி எண்.",
        invalidEmail: "{email} செல்லுபடியாகாத மின்னஞ்சல் முகவரி.",
        questionIsRequired: "இந்த கேள்வி தேவை.",
        answerIsNotValid: "இந்த பதில் செல்லுபடியாகாது.",
        unfinishedProbingDialogError: "அரட்டை விரிவாக்கத்தை முடிக்கவும்.",
        cannotResumePartialResponse: "பகுதி பதில்களைத் தொடர முடியாது.",
        failedToSubmitForm: "படிவத்தை சமர்ப்பிக்க முடியவில்லை. உங்கள் இணைய இணைப்பைச் சரிபார்த்து மீண்டும் முயற்சிக்கவும்."
    }
}
